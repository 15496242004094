import http from '@/libs/axios'

export const contributionPayments = {
  namespaced: true,
  state: {
    contributionPayments: [],
    outstandingContributors: [],
    contributor: null,
    paymentSettings: {
      selectedContributionId: null,
    },
    outstandingSettings: {
      selectedContributionId: null,
    },
    contributionHistory: {
      all_payments: [],
      all_outstanding: [],
    },
  },
  mutations: {
    setSettings: (state, settings) => {
      state.paymentSettings = { ...state.paymentSettings, ...settings }
    },
    setOutstandingSettings: (state, settings) => {
      state.outstandingSettings = { ...state.outstandingSettings, ...settings }
    },
    setOutstandingContributors: (state, outstanding) => {
      state.outstandingContributors = outstanding
    },
    setContributionPayments: (state, cp) => {
      state.contributionPayments = cp
    },
    setContributionHistory: (state, contributionHistory) => {
      state.contributionHistory = contributionHistory
    },
    setContributor: (state, contributor) => {
      state.contributor = contributor
    },
    addContributionPayment: (state, setting) => {
      // if (setting.contribution_id !== state.paymentSettings.selectedContributionId) {
      //   state.paymentSettings = { ...state.paymentSettings, selectedContributionId: setting.contribution_id }
      // }
      state.contributionPayments = [setting, ...state.contributionPayments]
    },
    updateContributionPayment: (state, setting) => {
      // if (setting.contribution_id !== state.paymentSettings.selectedContributionId) {
      //   state.paymentSettings = { ...state.paymentSettings, selectedContributionId: setting.contribution_id }
      // }
      state.contributionPayments = state.contributionPayments.map(datum => {
        if (datum.id === setting.id) {
          return setting
        }
        return datum
      })
    },
    removeContributionPayment: (state, id) => {
      state.contributionPayments = state.contributionPayments.filter(datum => datum.id !== id)
    },
  },
  actions: {
    createContribution({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('contribution/payments', data).then(res => {
          commit('addContributionPayment', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateContribution({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`contribution/payments/${data.id}`, data).then(res => {
          commit('updateContributionPayment', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteContribution({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`contribution/payments/${id}`).then(res => {
          commit('removeContributionPayment', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchContributionPayments({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.get(`contribution/payments/${id}`)
          .then(res => {
            commit('setContributionPayments', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getContributorsWithOutstandingDues({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.get(`contribution/outstanding/${id}`)
          .then(res => {
            commit('setOutstandingContributors', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getAmountToPay({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.get(`contribution/payments/amount-to-pay?contributor_id=${data.contributor_id}&contribution_id=${data.contribution_id}&is_member=${data.is_member}`)
          .then(res => {
            commit('setContributor', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    generateReceipts({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`contribution/payments/${data.id}/receipts`, { ids: data.ids })
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    generateContributorReceipts({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('contribution/receipts-for-contributor', data)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    requestPayments({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.post(`contribution/payments/${id}/request`)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getPaymentDetails({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.get(`contribution/payments/payment-details?payment_payload=${data}`)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getContributionHistory({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.get(`contribution/contribution-history/${data.id}?contributor_type=${data.type}`)
          .then(res => {
            commit('setContributionHistory', res.data)
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    initiatePayment({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('contribution/payments/initialize-payment', data)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {
    contributionPayments(state) {
      return state.contributionPayments
    },
    contributor(state) {
      return state.contributor
    },
    outstanding(state) {
      return state.outstandingContributors
    },
    paymentSettings(state) {
      return state.paymentSettings
    },
    paymentSettingId(state) {
      return state.paymentSettings.selectedContributionId
    },
    outstandingSettings(state) {
      return state.outstandingSettings
    },
    contributionHistory(state) {
      return state.contributionHistory
    },
  },
}
