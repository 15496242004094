import http from '@/libs/axios'

export const company = {
  namespaced: true,
  state: {
    company: {},
    classifications: [],
    currency: {},
    configuration: {},
  },
  mutations: {
    setClassifications(state, data) {
      state.classifications = data.classifications
      state.currency = data.currency
      state.configuration = data.configuration
    },
    setCompany(state, data) {
      state.company = data
    },
    setLogo(state, logo) {
      state.company.logo = logo
    },
    setCurrency(state, currency) {
      state.currency = currency ?? {}
    },
  },
  actions: {
    updateCompany({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`admin/companies/${state.company.id}`, data).then(res => {
          commit('setCompany', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateLogo({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`admin/companies/${state.company.id}/update-logo`, data).then(res => {
          commit('setLogo', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateCurrency({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`admin/companies/${state.company.id}/update-currency`, data).then(res => {
          commit('setCurrency', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateConfiguration({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`admin/companies/${state.company.id}/update-configuration`, data).then(res => {
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchClassifications({ commit }) {
      http.get('system/classifications').then(res => {
        commit('setClassifications', res.data)
      })
    },
  },
  getters: {
    companyLogo(state) {
      return state.company?.logo ? state.company?.logo : null
    },
    classifications(state) {
      return state.classifications
    },
    company(state) {
      return state.company
    },
    currency(state) {
      return state.currency
    },
    configuration(state) {
      return state.configuration
    },
    currencySymbol(state) {
      return state.currency.symbol
    },
  },
}
