import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import administration from './administration'
import membership from './membership'
import auth from './auth'
import contribution from './contribution'
import documents from './documents'
import dashboard from './dashboard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        redirectIfGuest: true,
      },
    },
    ...auth,
    ...administration,
    ...membership,
    ...contribution,
    ...documents,
    ...dashboard,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/dues-payment',
      name: 'dues-payment',
      component: () => import('@/views/DuesPayment.vue'),
      meta: {
        layout: 'full',
        allow: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach(async (to, _, next) => {
  await store.restored
  if (to.meta.allow) {
    return next()
  }
  const isLoggedIn = store.getters['auth/isLoggedIn']
  // if (!canNavigate(to)) {
  //   // If not logged in => login
  //   if (!isLoggedIn) return next({ name: 'login' })
  //   // If logged in => not authorized
  //   return next({ name: 'unauthorized' })
  // }
  // Redirect if logged in
  if (to.meta.redirectIfGuest && !isLoggedIn) {
    return next({ name: 'login' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/')
  }

  return next()
})
export default router

