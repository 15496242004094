import http from '@/libs/axios'

export const numbering = {
  namespaced: true,
  state: {
    numberings: [],
    joinReasons: [],
    membershipSizes: [],
    countries: [],
    regions: [],
  },
  mutations: {
    setNumberings: (state, numbs) => {
      state.numberings = numbs
    },
    addNumbering: (state, num) => {
      state.numberings = [num, ...state.numberings]
    },
    updateNumbering: (state, num) => {
      state.numberings = state.numberings.map(datum => {
        if (datum.id === num.id) {
          return num
        }
        return datum
      })
    },
    removeNumbering: (state, id) => {
      state.numberings = state.numberings.filter(datum => datum.id !== id)
    },
  },
  actions: {
    createNumbering({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('admin/numberings', data).then(res => {
          commit('addNumbering', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateNumbering({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`admin/numberings/${data.id}`, data.numbering).then(res => {
          commit('updateNumbering', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteNumbering({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`admin/numberings/${id}`).then(res => {
          commit('removeNumbering', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchNumberings({ commit }) {
      http.get('admin/numberings').then(res => {
        commit('setNumberings', res.data)
      })
    },
  },
  getters: {
    numberings(state) {
      return state.numberings
    },
  },
}
