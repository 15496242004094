import http from '@/libs/axios'

export const memberDashboard = {
  namespaced: true,
  state: {
    summary: {
      totalOrganizations: 0,
      totalActiveMembers: 0,
      totalInactiveMembers: 0,
      totalDueRetirement: 0,
    },
    genders: {
      males: 0,
      females: 0,
    },
    organizationMembers: {
      males: 0,
      females: 0,
    },
    ageDistribution: {},
    newMembers: [],
    memberRegistrations: {
      this_year_data: [],
      last_year_data: [],
    },
    dashboardSettings: {
      selectedStatus: null,
      selectedOrganization: null,
    },
  },
  mutations: {
    setSettings: (state, dashboardSettings) => {
      state.dashboardSettings = { ...state.dashboardSettings, ...dashboardSettings }
    },
    setSummary: (state, summary) => {
      state.summary.totalOrganizations = summary.total_organizations
      state.summary.totalActiveMembers = summary.total_active_members
      state.summary.totalInactiveMembers = summary.total_inactive_members
      state.summary.totalDueRetirement = summary.total_due_retirement
    },
    setGenders: (state, genders) => {
      state.genders = genders
    },
    setMemberRegistrations: (state, memberRegistrations) => {
      state.memberRegistrations = memberRegistrations
    },
    setOrganizationMembers: (state, organizationMembers) => {
      state.organizationMembers = organizationMembers
    },
    setAgeDistribution: (state, ageDistribution) => {
      state.ageDistribution = ageDistribution
    },
    setNewMembers: (state, newMembers) => {
      state.newMembers = newMembers
    },
  },
  actions: {
    getSummary({ commit }) {
      http.get('dashboard/membership/summary').then(res => {
        commit('setSummary', res.data)
      })
    },
    getGenders({ commit }, status) {
      http.get(`dashboard/membership/genders?member_status=${status}`).then(res => {
        commit('setGenders', res.data)
      })
    },
    getOrganizationMembers({ commit }, id) {
      http.get(`dashboard/membership/organizations/${id}/members`).then(res => {
        commit('setOrganizationMembers', res.data)
      })
    },
    getAgeDistribution({ commit }) {
      http.get('dashboard/membership/age-distribution').then(res => {
        commit('setAgeDistribution', res.data)
      })
    },
    getNewMembers({ commit }) {
      http.get('dashboard/membership/new-members').then(res => {
        commit('setNewMembers', res.data)
      })
    },
    getMemberRegistrations({ commit }) {
      http.get('dashboard/membership/member-registrations').then(res => {
        commit('setMemberRegistrations', res.data)
      })
    },
  },
  getters: {
    memberRegistrations(state) {
      return state.memberRegistrations
    },
    summary(state) {
      return state.summary
    },
    genders(state) {
      return state.genders
    },
    ageDistribution(state) {
      return state.ageDistribution
    },
    newMembers(state) {
      return state.newMembers
    },
    organizationMembers(state) {
      return state.organizationMembers
    },
    dashboardSettings(state) {
      return state.dashboardSettings
    },
  },
}
