// eslint-disable-next-line import/prefer-default-export
import http from '@/libs/axios'

export const accountUsers = {
  namespaced: true,
  state: {
    roles: [],
    users: [],
    permissions: [],
  },
  mutations: {
    setUsers(state, data) {
      state.users = data.users
    },
    setRoles(state, data) {
      state.roles = data
    },
    setRolesUsersPermissions(state, data) {
      state.users = data.users
      state.roles = data.roles
      state.permissions = data.permissions
    },
    addUser: (state, user) => {
      state.users = [user, ...state.users]
    },
    updateUser: (state, user) => {
      state.users = state.users.map(datum => {
        if (datum.id === user.id) {
          return user
        }
        return datum
      })
    },
    removeUser: (state, id) => {
      state.users = state.users.filter(datum => +datum.id !== +id)
    },
    updateUserStatus(state, user) {
      const foundUser = state.users.find(u => +u.id === +user.id)
      foundUser.active = user.status
    },
    addRole: (state, role) => {
      state.roles = [role, ...state.roles]
    },
    updateRole: (state, role) => {
      state.roles = state.roles.map(datum => {
        if (datum.id === role.id) {
          return role
        }
        return datum
      })
    },
    removeRole: (state, id) => {
      state.roles = state.roles.filter(datum => +datum.id !== +id)
    },

    updateRolePermissions(state, role) {
      const foundRole = state.roles.find(r => +r.id === +role.role_id)
      foundRole.permissions = role.permissions
    },
  },
  actions: {
    createRole({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('admin/roles', data).then(res => {
          commit('addRole', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateRole({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`admin/roles/${data.id}`, data).then(res => {
          commit('updateRole', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteRole({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`admin/roles/${id}`).then(res => {
          commit('removeRole', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getRolesUsersPermissions({ commit }) {
      http.get('system/roles-users-permissions').then(res => {
        commit('setRolesUsersPermissions', res.data)
      })
    },

    createUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('admin/users', data).then(res => {
          commit('addUser', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`admin/users/${data.id}`, data).then(res => {
          commit('updateUser', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`admin/users/${id}`).then(res => {
          commit('removeUser', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchUsers({ commit }) {
      http.get('admin/users').then(res => {
        commit('setUsers', res.data)
      })
    },
    fetchRoles({ commit }) {
      http.get('admin/roles').then(res => {
        commit('setRoles', res.data)
      })
    },
    resendCredentials({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.post(`admin/users/${id}/resend-credentials`).then(res => {
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    activateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`admin/users/${data.id}/activate`, data).then(res => {
          commit('updateUserStatus', data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    assignPermissions({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`admin/roles/${data.role_id}/assign-permissions`, { permissions: data.permissions }).then(res => {
          commit('updateRolePermissions', { permissions: res.data, role_id: data.role_id })
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {
    users(state) {
      return state.users
    },
    roles(state) {
      return state.roles
    },
    permissions(state) {
      return state.permissions
    },
  },
}
