import http from '@/libs/axios'

export const contributionSettings = {
  namespaced: true,
  state: {
    contributionSettings: [],
    contributor: null,
  },
  mutations: {
    setContributionSettings: (state, settings) => {
      state.contributionSettings = settings
    },
    setContributor: (state, contributor) => {
      state.contributor = contributor
    },
    addContribution: (state, setting) => {
      state.contributionSettings = [setting, ...state.contributionSettings]
    },
    updateContribution: (state, setting) => {
      state.contributionSettings = state.contributionSettings.map(datum => {
        if (datum.id === setting.id) {
          return setting
        }
        return datum
      })
    },
    removeContribution: (state, id) => {
      state.contributionSettings = state.contributionSettings.filter(datum => datum.id !== id)
    },
  },
  actions: {
    createContribution({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('contribution/contributions', data).then(res => {
          commit('addContribution', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateContribution({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`contribution/contributions/${data.id}`, data).then(res => {
          commit('updateContribution', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteContribution({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`contribution/contributions/${id}`).then(res => {
          commit('removeContribution', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchContributionSettings({ commit }) {
      return new Promise((resolve, reject) => {
        http.get('contribution/contributions')
          .then(res => {
            commit('setContributionSettings', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getMember({ commit }, memberId) {
      return new Promise((resolve, reject) => {
        http.get(`contribution/member-for-contribution/${memberId}`)
          .then(res => {
            commit('setContributor', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getOrganization({ commit }, organizationId) {
      return new Promise((resolve, reject) => {
        http.get(`contribution/organization-for-contribution/${organizationId}`)
          .then(res => {
            commit('setContributor', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {
    contributionSettings(state) {
      return state.contributionSettings
    },
    contributor(state) {
      return state.contributor
    },
  },
}
