import http from '@/libs/axios'

export const member = {
  namespaced: true,
  state: {
    paginatedMembersData: {},
    membershipSettings: {
      memberStatus: null,
      currentPage: 1,
      perPage: 10,
      searchTerm: null,
      sort: {
        column: 'created_at',
        order: 'desc',
      },
    },
  },
  mutations: {
    setMembers: (state, mems) => {
      state.paginatedMembersData = mems
    },
    setSettings: (state, settings) => {
      state.membershipSettings = { ...state.membershipSettings, ...settings }
    },
    addMember: (state, mem) => {
      state.paginatedMembersData.data = [mem, ...state.paginatedMembersData.data]
    },
    updateMember: (state, mem) => {
      state.paginatedMembersData.data = state.paginatedMembersData.data.map(datum => {
        if (datum.id === mem.id) {
          return mem
        }
        return datum
      })
    },
    removeMember: (state, id) => {
      state.paginatedMembersData.data = state.paginatedMembersData.data.filter(datum => datum.id !== id)
    },
  },
  actions: {
    createMember({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('membership/members', data).then(res => {
          commit('addMember', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateMember({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`membership/members/${data.id}`, data.member).then(res => {
          commit('updateMember', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteMember({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`membership/members/${id}`).then(res => {
          commit('removeMember', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchMembers({ commit }, query) {
      return new Promise((resolve, reject) => {
        http.get(`membership/members?member_status=${query.status || ''}&search=${query.search || ''}&page=${query.page}&per_page=${query.per_page}&sort_by=${query.column}&sort_order=${query.order}`)
          .then(res => {
            commit('setMembers', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    sendNotification({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('membership/notifications', data).then(res => {
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    generateIdCards({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('membership/members/generate-id-cards', data).then(res => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getLocationData({ commit }, data) {
      http.post('system/location-data', data).then(res => {
        commit("organization/setLocationData",res,{root: true})
      })
    },
  },
  getters: {
    members(state) {
      return state.paginatedMembersData.data
    },
    membershipSettings(state) {
      return state.membershipSettings
    },
    totalRows(state) {
      return state.paginatedMembersData.total_rows
    },
  },
}
