import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import {
  Dropdown, Menu,
} from 'floating-vue'
import i18n from '@/libs/i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFilePdf, faFileWord, faFilePowerpoint, faFileExcel, faFolder, faFolderPlus, faFolderOpen,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook, faTwitter, faLinkedin, faWhatsapp, faGooglePlus,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import VueCurrencyFilter from 'vue-currency-filter'
import SocialSharing from 'vue-social-sharing'
import App from './App.vue'
import store from './store'
import router from './router'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import 'floating-vue/dist/style.css'
import './registerServiceWorker'

library.add(faFolder, faFolderPlus, faFilePdf, faFolderOpen, faFileWord, faFacebook, faTwitter, faLinkedin, faWhatsapp, faGooglePlus, faFilePowerpoint, faFileExcel)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.use(SocialSharing)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCurrencyFilter,
  {
    symbol: '',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: '00',
  })
// Composition API
Vue.use(VueCompositionAPI)

Vue.component('VDropdown', Dropdown)
Vue.component('VMenu', Menu)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
