import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

axiosIns.interceptors.request.use(config => {
  config.headers.Accept = 'application/json'
  config.headers['Content-Type'] = 'application/json'
  config.headers.Authorization = `Bearer ${store.getters['auth/accessToken']}`
  return config
})
Vue.prototype.$http = axiosIns

export default axiosIns
