import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  computed: {
    noNetworkOrInternet() {
      return this.$store.getters['app/networkStatus']
    },
  },
  methods: {
    showInfo(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Information',
          icon: 'InfoIcon',
          text: message,
          variant: 'warning',
        },
      })
    },
    showServiceWorkerCached() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Request Cached',
          icon: 'CheckCircleIcon',
          text: 'Request Cached Successfully, You can manually clear and close the form.',
          variant: 'warning',
        },
      })
    },
    showServiceWorkerSyncSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Requests Synced/Replayed',
          icon: 'CheckCircleIcon',
          text: 'Previously Cached Requests Successfully Synced with Server',
          variant: 'success',
        },
      })
    },
    showSuccess(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          text: message,
          variant: 'success',
        },
      })
    },

    showError(message) {
      if (!this.noNetworkOrInternet) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XCircleIcon',
            text: message,
            variant: 'danger',
          },
        })
      }
      this.$store.commit('app/setNetworkStatus', false)
    },
  },
}
