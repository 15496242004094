export default [
  {
    path: '/administration',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'company',
        name: 'company',
        component: () => import('@/views/administration/company/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-company',
        },
      },
      {
        path: 'organizations',
        name: 'organizations',
        component: () => import('@/views/administration/organizations/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-organizations',
        },
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/administration/users/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-users',
        },
      },
      {
        path: 'numberings',
        name: 'numberings',
        component: () => import('@/views/administration/numberings/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'administration',
          action: 'access-numberings',
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/auth/Profile.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'farmbusiness',
          action: 'access-profile',
        },
      },
    ],
  },
]
