import http from '@/libs/axios'

export const grants = {
  namespaced: true,
  state: {
    grants: [],
  },
  mutations: {
    setGrants: (state, numbs) => {
      state.grants = numbs
    },
    addGrant: (state, num) => {
      state.grants = [num, ...state.grants]
    },
    updateGrant: (state, num) => {
      state.grants = state.grants.map(datum => {
        if (datum.id === num.id) {
          return num
        }
        return datum
      })
    },
    removeGrant: (state, id) => {
      state.grants = state.grants.filter(datum => datum.id !== id)
    },
  },
  actions: {
    createGrant({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('contribution/grants', data).then(res => {
          commit('addGrant', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateGrant({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`contribution/grants/${data.id}`, data).then(res => {
          commit('updateGrant', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteGrant({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`contribution/grants/${id}`).then(res => {
          commit('removeGrant', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchGrants({ commit }) {
      http.get('contribution/grants').then(res => {
        commit('setGrants', res.data)
      })
    },
  },
  getters: {
    grants(state) {
      return state.grants
    },
  },
}
