export default [
  {
    path: '/contribution',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/contribution/settings/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'contribution',
          action: 'access-contribution-settings',
        },
      },
      {
        path: 'payments',
        name: 'payments',
        component: () => import('@/views/contribution/payments/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'contribution',
          action: 'access-contribution-payments',
        },
      },
      {
        path: 'outstanding',
        name: 'outstanding',
        component: () => import('@/views/contribution/outstanding/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'contribution',
          action: 'access-contribution-payments',
        },
      },
      {
        path: 'grants',
        name: 'grants',
        component: () => import('@/views/contribution/grants/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'grants',
          action: 'access-grants',
        },
      },
    ],
  },
]
