import http from '@/libs/axios'

export const documentsDashboard = {
  namespaced: true,
  state: {
    summary: {
      folders: 0,
      files: 0,
      trash: 0,
    },
  },
  mutations: {
    setSummary: (state, stats) => {
      state.summary = stats
    },
  },
  actions: {
    getDocsStats({ commit }) {
      http.get('dashboard/documents/summary').then(res => {
        commit('setSummary', res.data)
      })
    },
  },
  getters: {
    docsStats(state) {
      return state.summary
    },
  },
}
