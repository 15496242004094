export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
]
