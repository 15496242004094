import http from '@/libs/axios'
import {resetState} from "@/store";

export const auth = {
  namespaced: true,
  state: {
    user: {},
    company: {},
    token: null,
    refreshToken: null,
    classifications: [],
  },
  mutations: {
    setAuthState(state, token) {
      state.token = token.access_token
      state.refreshToken = token.refresh_token
      state.user = token.user
    },
    setAccessToken(state, token) {
      state.token = token
    },
    // eslint-disable-next-line no-unused-vars
    clearAuthState(state) {
      state.token = null
      state.refreshToken = null
      resetState()
    },
    setLoggedOut(state) {
      state.token = null
      state.refreshToken = null
      resetState()
    },
    removePhoto(state) {
      state.user.photo = null
    },
    updateUser(state, user) {
      state.user = { ...state.user, ...user }
    },
    updateUserPhoto(state, photo) {
      state.user.photo = photo
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('auth/login', data).then(res => {
          commit('setAuthState', res.data)
          commit('company/setCompany', res.data.company, { root: true })
          commit('company/setCurrency', res.data.currency, { root: true })
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    requestReset({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('auth/request-password-reset', data).then(res => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    logout({ commit }) {
      return new Promise(resolve => {
        http.post('auth/logout').finally(res => {
          commit('clearAuthState')
          resolve(true)
        })
      })
    },
    refreshToken({ state, commit }) {
      return new Promise((resolve, reject) => {
        http.post(`auth/refresh?refresh_token=${state.refreshToken}`).then(res => {
          commit('setAccessToken', res.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`auth/update-user/${data.id}`, data).then(res => {
          commit('updateUser', res.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updatePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('auth/update-password', data).then(res => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    removeUserPhoto({ commit }) {
      return new Promise((resolve, reject) => {
        http.post('auth/remove-user-photo').then(res => {
          commit('removePhoto', res.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateUserPhoto({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('auth/update-photo', data).then(res => {
          commit('updateUserPhoto', res.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    },
    accessToken(state) {
      return state.token
    },
    refreshToken(state) {
      return state.refreshToken
    },
    user(state) {
      return state.user
    },
  },
}
