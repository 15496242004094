import http from '@/libs/axios'

export const documents = {
  namespaced: true,
  state: {
    files: [],
    trashFiles: [],
    settings: {
      currentFolder: null,
      sortColumn: null,
      sortDirection: null,
    },
  },
  mutations: {
    setSettings: (state, settings) => {
      state.settings = { ...state.settings, ...settings }
    },
    setTrashFiles: (state, files) => {
      state.trashFiles = files
    },
    setFiles: (state, files) => {
      state.files = files
    },
    addFile: (state, file) => {
      state.files = [file, ...state.files]
    },
    addFiles: (state, files) => {
      state.files = [...files, ...state.files]
    },
    updateFile: (state, file) => {
      state.files = state.files.map(datum => {
        if (datum.id === file.id) {
          return file
        }
        return datum
      })
    },
    removeFiles: (state, ids) => {
      state.files = state.files.filter(datum => !ids.includes(datum.id))
    },
    removeTrashFiles: (state, ids) => {
      state.trashFiles = state.trashFiles.filter(datum => !ids.includes(datum.id))
    },
  },
  actions: {
    createNotes({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/notes', data).then(res => {
          commit('addFile', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    createFile({ commit }, data) {
      return new Promise((resolve, reject) => {
        let url = 'create'
        if (data.folderId) {
          url = `${url}?folder_id=${data.folderId}`
        }
        http.post(`documents/files/${url}`, data.file).then(res => {
          commit('addFile', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateFile({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`documents/files/${data.id}`, data).then(res => {
          commit('updateFile', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteFiles({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`documents/files/delete?delete_permanently=${data.permanent}`, { ids: data.ids }).then(res => {
          commit('removeFiles', data.ids)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteFromTrash({ commit }, ids) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/delete?from_trash=true', { ids }).then(res => {
          commit('removeTrashFiles', ids)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    restoreFiles({ commit }, ids) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/restore', { ids }).then(res => {
          commit('removeTrashFiles', ids)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    uploadFiles({ commit }, files) {
      return new Promise((resolve, reject) => {
        http.post(`documents/files/${files.id}`, files.data).then(res => {
          commit('addFiles', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchFiles({ commit }, folderId) {
      return new Promise((resolve, reject) => {
        let url = 'files'
        if (folderId) {
          url = `${url}?folder_id=${folderId}`
        }
        http.get(`documents/${url}`)
          .then(res => {
            commit('setFiles', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getTrashFiles({ commit }) {
      return new Promise((resolve, reject) => {
        http.get('documents/files/trash')
          .then(res => {
            commit('setTrashFiles', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getMember({ commit }, search) {
      return new Promise((resolve, reject) => {
        http.get(`documents/member-recipient?search_term=${search}`)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getOrganization({ commit }, search) {
      return new Promise((resolve, reject) => {
        http.get(`documents/organization-recipient?search_term=${search}`)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    shareFiles({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/share', data)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getContributionSummary({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.get('dashboard/contribution-summary', data)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {
    files(state) {
      return state.files
    },
    trashFiles(state) {
      return state.trashFiles
    },
    settings(state) {
      return state.settings
    },
  },
}
