export default [
  {
    path: '/dashboard',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'membership',
        name: 'membership-db',
        component: () => import('@/views/dashboard/membership/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'dashboard',
          action: 'access-membership-dashboard',
        },
      },
      {
        path: 'contribution',
        name: 'contribution-db',
        component: () => import('@/views/dashboard/contribution/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'dashboard',
          action: 'access-contribution-dashboard',
        },
      },
      {
        path: 'documents',
        name: 'documents-db',
        component: () => import('@/views/dashboard/documents/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'dashboard',
          action: 'access-documents-dashboard',
        },
      },
    ],
  },
]
