import http from '@/libs/axios'

export const contributionDashboard = {
  namespaced: true,
  state: {
    summary: {
      member_dues_collected: 0,
      member_dues_outstanding: 0,
      pension_collected: 0,
      pension_outstanding: 0,
      organization_dues_collected: 0,
      organization_dues_outstanding: 0,
      registration_fee_collected: 0,
      registration_fee_outstanding: 0,
      grants_received: 0,
    },
    duesPayments: {},
    outstanding: [],
    dashboardSettings: {
      selectedDuesPayment: null,
      selectedOutstandingDues: null,
    },
  },
  mutations: {
    setSettings: (state, dashboardSettings) => {
      state.dashboardSettings = { ...state.dashboardSettings, ...dashboardSettings }
    },
    setDuesPayments: (state, data) => {
      state.duesPayments = data
    },
    setOutstandingDues: (state, data) => {
      state.outstanding = data
    },
    setSummary: (state, summary) => {
      state.summary.member_dues_collected = summary.tmd
      state.summary.member_dues_outstanding = summary.tmd_out
      state.summary.pension_collected = summary.tmp
      state.summary.pension_outstanding = summary.tmp_out
      state.summary.organization_dues_collected = summary.tod
      state.summary.organization_dues_outstanding = summary.tod_out
      state.summary.registration_fee_collected = summary.tor
      state.summary.registration_fee_outstanding = summary.tor_out
      state.summary.grants_received = summary.tgc
    },
  },
  actions: {
    getSummary({ commit }) {
      http.get('dashboard/contributions/summary').then(res => {
        commit('setSummary', res.data)
      })
    },
    getDuesPayments({ commit }, type) {
      http.get(`dashboard/contributions/dues-payments?dues_type=${type}`).then(res => {
        commit('setDuesPayments', res.data)
      })
    },
    getOutstandingDues({ commit }, isMember) {
      let path = 'organization-outstanding-dues'
      if (isMember) {
        path = 'member-outstanding-dues'
      }
      http.get(`dashboard/contributions/${path}`).then(res => {
        commit('setOutstandingDues', res.data)
      })
    },

  },
  getters: {
    summary(state) {
      return state.summary
    },
    duesPayments(state) {
      return state.duesPayments
    },
    outstanding(state) {
      return state.outstanding
    },
    dashboardSettings(state) {
      return state.dashboardSettings
    },
  },
}
