export default [
  {
    path: '/membership',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'members',
        name: 'members',
        component: () => import('@/views/membership/members/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'members',
          action: 'access-members',
        },
      },
      {
        path: 'id-customization',
        name: 'id-customization',
        component: () => import('@/views/membership/member-id-card/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'members',
          action: 'access-id-customization',
        },
      },
    ],
  },
]
