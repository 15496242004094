import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import VuexPersistence from 'vuex-persist'

import { auth } from './auth'
import { company } from './admin/company'
import { organization } from './admin/organization'
import { numbering } from './admin/numbering'
import { accountUsers } from './admin/users'
import { member } from './membership/member'
import { memberId } from './membership/member-id'
import { contributionSettings } from './contribution/settings'
import { contributionPayments } from './contribution/payment'
import { grants } from './contribution/grant'
import { documents } from './documents'
import { memberDashboard } from './dashboard/member'
import { documentsDashboard } from './dashboard/documents'
import { contributionDashboard } from './dashboard/contribution'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import localForage from "localforage";

const vuexLocal = new VuexPersistence({
  key: 'farmbusiness',
  storage: localForage.createInstance({
    storeName: 'farmbusiness',
    version: 1,
  }),
  asyncStorage: true,
  modules: [
    'auth',
    'company',
    'organization',
    'numbering',
    'accountUsers',
    'member',
    'memberId',
    'contributionSettings',
    'contributionPayments',
    'grants',
    'documents',
    'memberDashboard',
    'documentsDashboard',
    'contributionDashboard',
  ],
})

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    company,
    organization,
    numbering,
    accountUsers,
    member,
    memberId,
    contributionSettings,
    contributionPayments,
    grants,
    documents,
    memberDashboard,
    documentsDashboard,
    contributionDashboard
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
})
export default store;
const initialStateCopy = JSON.parse(JSON.stringify(store.state))

export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
}
