import http from '@/libs/axios'

export const organization = {
  namespaced: true,
  state: {
    organizations: [],
    joinReasons: [],
    membershipSizes: [],
    countries: [],
    regions: [],
  },
  mutations: {
    setRequiredData(state, data) {
      state.joinReasons = data.join_reasons
      state.membershipSizes = data.membership_sizes
      state.countries = data.countries
      state.regions = data.regions
    },
    setLocationData(state, data) {
      state.countries = data.countries
      state.regions = data.regions
    },
    setOrganizations: (state, orgs) => {
      state.organizations = orgs
    },
    addOrganization: (state, org) => {
      state.organizations = [org, ...state.organizations]
    },
    updateOrganization: (state, org) => {
      state.organizations = state.organizations.map(datum => {
        if (datum.id === org.id) {
          return org
        }
        return datum
      })
    },
    removeOrganization: (state, id) => {
      state.organizations = state.organizations.filter(datum => datum.id !== id)
    },
  },
  actions: {
    createOrganization({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('admin/organizations', data).then(res => {
          commit('addOrganization', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateOrganization({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`admin/organizations/${data.id}`, data.organization).then(res => {
          commit('updateOrganization', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteOrganization({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.delete(`admin/organizations/${id}`).then(res => {
          commit('removeOrganization', id)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchOrganizations({ commit }) {
      http.get('admin/organizations').then(res => {
        commit('setOrganizations', res.data)
      })
    },
    fetchRequiredData({ commit }) {
      http.get('system/data-required-to-create-organization').then(res => {
        commit('setRequiredData', res.data)
      })
    },
    sendNotification({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('admin/organizations/notifications', data).then(res => {
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {
    organizations(state) {
      return state.organizations
    },
    membershipSizes(state) {
      return state.membershipSizes
    },
    joinReasons(state) {
      return state.joinReasons
    },
    countries(state) {
      return state.countries
    },
    regions(state) {
      return state.regions
    },
  },
}
