export default [
  {
    path: '/documents',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'folders',
        name: 'folders',
        component: () => import('@/views/documents/folders/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'settings',
          action: 'access-documents-settings',
        },
      },
      {
        path: 'trash',
        name: 'trash',
        component: () => import('@/views/documents/trash/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'trash-store',
          action: 'access-trash-store',
        },
      },
    ],
  },
]
